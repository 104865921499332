import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>CV</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiență</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">Feb 2019 - Feb 2020</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Full Stack Developer</span>
                                    <span className="d_company"><a href="https://vlinde.de" style={{color: '#cf1f1f'}}>Vlinde Transylvania</a></span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">Mai 2020 - Feb 2022</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Full-stack Developer</span>
                                    <span className="d_company"><a href="https://retargeting.biz/en"
                                                                   style={{color: '#cf1f1f'}}>Retargeting</a></span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">Feb 2022 - Feb 2023</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Developer</span>
                                    <span className="d_company"><a href="https://pentest-tools.com/"
                                                                   style={{color: "#cf1f1f"}}>Pentest-Tools.com</a></span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">Oct 2022 - Dec 2023</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Developer</span>
                                    <span className="d_company"><a href="https://stefanini.com/en"
                                                                   style={{color: "#cf1f1f"}}>Stefanini EMEA - FAN Courier</a></span>
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">Ian 2024 - Prezent</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Developer</span>
                                    <span className="d_company"><a href="https://fancourier.ro"
                                                                   style={{color: "#cf1f1f"}}>FAN Courier</a></span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6"
                     data-aos="fade-up"
                     data-aos-once="true"
                >
                    <div className="p-4">
                        <h3 className="s_border">Educație</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2013 - Prezent</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Programator</span>
                                    Sunt o persoană dedicată și pasionată de orice tehnologie inovativă care mă poate
                                    ajuta să duc la capăt un proiect cât mai corect și rapid, căutând mereu să-mi îmbunătățesc cunoștințele și să învăț noi tehnologii.
                                    </p>
                                <p className="d_timeline-text">
                                    Am urmat cursuri de formare de pe Udemy, acoperind o gamă diversă de subiecte legate de programare. Însă nu m-am limitat doar la cursuri formale, ci am căutat și alte surse de cunoaștere, cum ar fi canalele de YouTube specializate în programare. Tocmai această educația mea legată de programare reflectă angajamentul și entuziasmul meu pentru acest domeniu.
                                </p>
                                <p className="d_timeline-text">
                                    Am simțit emoția fiecărui pas în călătoria mea digitală, încercând o multitudine de tehnologii și limbaje de programare. Am fost intrigat de arhitecturi complexe și am deschis cutii de Pandora în lumea dezvoltării web. În fiecare experiență, am fost învățat și inspirat de provocările și recompensele programării.
                                </p>

                                <p className="d_timeline-text">
                                    Cu fiecare nouă etapă în călătoria mea, mă simt încrezător că pot înfrunta orice provocare și că există întotdeauna ceva nou de descoperit în fascinanta lume a programării.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;