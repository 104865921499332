import React from 'react';
import emailjs from 'emailjs-com';
import { Parallax } from "react-parallax";
import AOS from 'aos';
AOS.init();

const image1 ="./img/background/5.jpg";
const contact = () => {

    function sendEmail(e) {

    const success = document.getElementById("success");
    const button = document.getElementById("send_message");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('service_a43t7nh', 'template_biy7y1d', e.target, 'UNDkxFzh6pKsX3wnD')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }

    return(
        <div className="section bg-top bg-bottom py-0">
            <Parallax className="py-5" bgImage={image1} strength={300}>  
                  <div className="py-5 position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="0"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                                >
                                <h2>Contactează-mă</h2>
                                <div className="space-border"></div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2"
                            data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="200"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                            >
                            <div className="contact_form_wrapper">
                                <form name="contactForm" id="contact_form" className="form-border" onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="field-set">
                                                <input type="text" name="name" id="name" className="form-control" placeholder="Numele tău" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field-set">
                                                <input type="email" name="email" id="email" className="form-control" placeholder="Email-ul" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field-set">
                                                <input type="text" name="phone" id="phone" className="form-control" placeholder="Numărul de telefon" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div className="field-set">
                                            <textarea name="message" id="message" className="form-control" placeholder="Un mesaj care să prezinte proiectul tău și ceea ce vrei să facem împreună." required></textarea>
                                        </div>
                                        <div id='success' className='hide'> <h4>Mesajul tau a fost trimis...</h4></div>
                                        <div id='failed' className='hide'>A aparut o eroare...</div>
                                        <div id='submit' className="mt30">
                                            <button type='submit' id='send_message' className="btn-main" title="Trimite mesajul">
                                                Let's code!
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div className="spacer-double"></div>
                            <div className="row text-center">
                                <div className="col-md-4"
                                    data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="0"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                                    >
                                    <div className="wm-1"></div>
                                    <h6>Email</h6>
                                    <p><a className="text-decoration-none " href="mailto:contact@vladutteodor.ro">contact@vladutteodor.ro</a></p>
                                </div>
                                <div className="col-md-4"
                                    data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="200"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                                    >
                                    <div className="wm-1"></div>
                                    <h6>Telefon</h6>
                                    <p>0773 379 253</p>
                                </div>
                                <div className="col-md-4"
                                    data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="400"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                                    >
                                    <div className="wm-1"></div>
                                    <h6>Firmă</h6>
                                    <p className="m-0">Nume: Auto Magic Cast SRL</p>
                                    <p className="m-0">CUI: 45606628</p>
                                    <p className="m-0">Adresă: Str. Pârâului Nr.2</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    );
}

export default contact;