import React from 'react';
import AOS from 'aos';
AOS.init();

const whatido = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>Cu ce mă ocup</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-4"
                     data-aos="fade"
                     data-aos-delay="400"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_tools id-color-2"></i>
                            <div className="text">
                                <h3>Dezvoltare</h3>
                                Cu pasiunea noastră pentru tehnologie, creăm soluții software inovatoare și personalizate pentru a transforma viziunile în realitate.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_cart id-color-2"></i>
                            <div className="text">
                                <h3>E-commerce</h3>
                                Putem crea o experiență de cumpărături memorabilă pentru clienții tăi. De la navigație facilă până la procese de plată sigure, suntem aici să-ți transformăm afacerea într-un brand puternic și competitiv pe piața digitală.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_mobile id-color-2"></i>
                            <div className="text">
                                <h3>Mobile</h3>
                                Cu o atenție deosebită la designul intuitiv, performanță și securitate, ne asigurăm că aplicația ta va oferi o experiență de utilizare deosebită și va atrage audiența dorită.
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-4"
                     data-aos="fade"
                     data-aos-delay="300"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_genius id-color-2"></i>
                            <div className="text">
                                <h3>Branding</h3>
                                Elevăm brandul tău la un nivel superior! Expertiza noastră în branding aduce valoare și vizibilitate afacerii tale. Creație, identitate și succes, toate într-un singur loc.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4"
                     data-aos="fade"
                     data-aos-delay="300"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_desktop id-color-2"></i>
                            <div className="text">
                                <h3>Design UI/UX</h3>
                                Crearea unui design atractiv, intuitiv și optimizat pentru aplicațiile web și mobile, asigurând o experiență de utilizare plăcută și ușoară pentru utilizatori.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4"
                     data-aos="fade"
                     data-aos-delay="300"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_toolbox id-color-2"></i>
                            <div className="text">
                                <h3>Integrări</h3>
                                Integrarea funcționalităților aplicațiilor cu servicii externe, cum ar fi API-uri, sisteme de plată, platforme de social media etc., pentru a îmbunătăți experiența utilizatorilor.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default whatido;