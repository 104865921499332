import React from 'react';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
					<h2 data-aos="fade-up"
						data-aos-delay="0"
						data-aos-duration="1000"
						data-aos-easing="ease"
						data-aos-once="true">Câteva cuvinte despre mine</h2>
					<div className="space-border"
						 data-aos="fade-up"
						 data-aos-delay="20"
						 data-aos-duration="1000"
						 data-aos-easing="ease"
						 data-aos-once="true"
					></div>
				</div>


				<div className="col-lg-4 text-center m-auto">
					<img src="./img/avatar-vladut.jpg" className="img-fluid" alt="" />
				</div>
				<div className="col-md-8 text-center m-auto"
					 data-aos="fade-up"
					 data-aos-delay="60"
					 data-aos-duration="1000"
					 data-aos-easing="ease"
					 data-aos-once="true"
				>
					<p>
						Salutare! Eu sunt Vlăduț 👋, am 27 de ani și sunt pasionat de programare și tehnologie de la
						vârsta de 15 ani. Povestea mea e cam așa:
					</p>
					<p>
						La 15 ani, pe când alții se uitau la desene animate și visau să devină astronauți 🚀, eu am
						descoperit magia programării. Eram un adolescent fără planuri de viitor, dar cu o pasiune
						ascunsă pentru tehnologie. În clasa a XI-a, când toți prietenii mei își făceau planuri mari 🎓,
						eu eram singurul fără direcție. Părea că viața mea mergea în buclă: școală, teme, jocuri pe
						calculator, repeta.
					</p>
					<p>
						Într-o zi, mi-am zis că trebuie să fac ceva util cu timpul meu, așa că am început să cumpăr
						cursuri online de programare. În curând, codam de parcă mâinile mele aveau superputeri 💪. Am
						trecut prin toate: de la scris cod până la testat, erori și, în cele din urmă, SUCCES!
					</p>

					<p>
						Astăzi, cu o jumătate de deceniu de experiență în spate, mă consider un self-made Software
						Developer și un adevărat problem solver 🧩. Pentru mine, programarea nu e doar despre scris cod;
						e despre înțelegerea nevoilor utilizatorilor și livrarea unui produs care face viața mai ușoară.
						Când alții își bat capul cu probleme, eu sunt acolo, gata să găsesc soluția perfectă ✅.
					</p>

					<p>
						Așa că, da, sunt Vlăduț Teodor, și sunt aici să cuceresc lumea tehnologiei 🌐.
					</p>
				</div>

			</div>

			<h4 className="mt-3 mb-2">Top tehnologii cu care mă mândresc (și am găsit poze drăguțe)</h4>
			<div className="row">
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/laravel/laravel-original-wordmark.svg" alt="Laravel Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original-wordmark.svg" alt="Vue.js Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain-wordmark.svg" alt="Docker Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nuxtjs/nuxtjs-original-wordmark.svg" alt="Nuxt.js Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg" alt="MySQL Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg" alt="MongoDB Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg" alt="Bash Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original-wordmark.svg" alt="Redis Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg" alt="Linux Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nginx/nginx-original.svg" alt="Nginx Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-plain-wordmark.svg" alt="Amazon Web Services Logo" />
				</div>
				<div className="col-lg-1 col-3">
					<img className="img-fluid" width="80" height="80" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rabbitmq/rabbitmq-original.svg" alt="Laravel Logo" />
				</div>
			</div>

		</div>
		</div>
	);
}

export default hero;